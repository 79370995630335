<template>
  <div id="fullscreen">
    <mds-modal
      id="fullscreen-model"
      v-model="toggleFullscreen"
      :title="(operation === 'edit' ? 'Edit Workflow - ' + `${workflowName}` : '')
        || (operation === 'view' ? 'View Workflow - ' + `${workflowName}` : '')
        || (operation === 'create' ? 'Create New Workflow' : '')"
      :action-required="true"
      :disable-focus-trap="true"
      @mds-modal-dismissed="closeModel($event)"
    >
      <mds-notification-container v-if="showError">
        <!-- Tinted -->
        <mds-notification
          key="error-tinted"
          variation="error"
          title="Error"
          tinted
          :dismiss-delay="3000"
          @mds-notification-dismissed="hideError"
        >
          <div v-html="errorMessage" />
        </mds-notification>
      </mds-notification-container>
      <mds-loader
        v-if="showLoader"
        aria-label="Default Loader"
      />
      <div v-else>
        <mds-tabs
          :content="tabsContent"
          @mds-tabs-item-active="setActiveItem"
        />
        <div
          v-if="tabsContent[0].active"
        >
          <workflow-diagram
            ref="wfDiagram"
            :zoom-enabled="false"
            :workflow="workflow"
            :workflow-name="workflowName"
            :workflow-data="workflowParameters"
            :bad-formula-bubbles="badFormulaBubbles"
            :is-editable="operation === 'edit' || operation === 'create' ? true : false"
            :formulas="formulas"
            :operation="operation"
            @diagramChanged="enableSaveCancelBtn"
            @editWorkflowNameFun="editWorkflowName"
            @wfNameErrorFun="wfNameErrorFun"
            @closeEdit="cancelModel"
          />
        </div>
        <div
          v-if="tabsContent[1] && tabsContent[1].active"
        >
          <workflow-inputs
            ref="input"
            :workflow-id="workflow.id.toString()"
            :operation="operation"
            @diagramChanged="enableSaveCancelBtn"
          />
        </div>
        <div
          v-if="tabsContent[2] && tabsContent[2].active"
        >
          <workflow-schedule
            :workflow-id="workflow.id.toString()"
            :operation="operation"
            @diagramChanged="enableSaveCancelBtn"
          />
        </div>
        <div
          v-if="tabsContent[3] && tabsContent[3].active"
        >
          <mds-textarea
            v-model="description"
            hidden-label
            label="Hidden Label"
            :disabled="disbaledTextArea"
            @keydown="chnageDesc"
          />
        </div>
        <div
          v-if="tabsContent[4] && tabsContent[4].active"
        >
          <workflow-debug
            v-if="!showLoader"
            :parameter-set-data="workflowParameters.workFlowJobModel.parameterSetGroupModel"
            :formulas="formulas"
            :workflow-config-data="workflow"
            :operation="operation"
          />
        </div>
        <div
          v-if="tabsContent[5] && tabsContent[5].active"
        >
          <workflow-history
            v-if="!showLoader"
            :parameter-set-data="workflowParameters.workFlowJobModel.parameterSetGroupModel"
            :formulas="formulas"
            :workflow-config-data="workflow"
            :operation="operation"
          />
        </div>
        <div
          v-if="showSaveCancelBtn
            && (operation === 'edit'
              || operation === 'create'
              || workflow.permissions.includes('formula_edit')
              || workflow.permissions.includes('input_edit'))
            && (badFormulaBubbles.length === 0)"
        >
          <mds-layout-grid style="position: absolute; bottom: 20px; right: 20px;">
            <mds-row>
              <mds-col :cols="11" />
              <mds-col>
                <mds-button-container>
                  <mds-button
                    variation="primary"
                    :disabled="wfNameError"
                    @click="openCommentDialoge()"
                  >
                    Submit
                  </mds-button>
                  <mds-button
                    variation="secondary"
                    @click="cancelModel()"
                  >
                    Cancel
                  </mds-button>
                </mds-button-container>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </div>
        <div v-else>
          <mds-layout-grid style="position: absolute; bottom: 20px; right: 20px;">
            <mds-row>
              <mds-col :cols="11" />
              <mds-col>
                <mds-button-container>
                  <mds-button
                    variation="primary"
                    @click="closeModel()"
                  >
                    Close
                  </mds-button>
                </mds-button-container>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </div>
      </div>
    </mds-modal>
    <mds-dialog
      id="target-missing-dialog"
      v-model="showTargetMissingDialog"
      title=""
      hidden-title
      width="500px"
    >
      <mds-alert
        variation="error"
        title="Validation Error"
        :persistent="true"
      >
        <div>
          <div>
            Marketplace error:
          </div><div /><div>
            Code: -415
          </div><div>
            URL: /lds/workflows/316
          </div><div /><div>
            Details:
          </div><div>
            Error parsing server response:
          </div><div /><div>
            {{ missingDialogErrorMessage }}
          </div><div /><div>
            See full details in JavaScript console.
          </div>
        </div>
      </mds-alert>
    </mds-dialog>
    <mds-dialog
      id="validation-dailog"
      v-model="showValidationFailedDialog"
      title="Validation Failed"
      @mds-dialog-dismissed="dissmissDialog"
    >
      {{ validationFailureMessage }}
    </mds-dialog>
    <mds-dialog
      id="comment-dailog"
      v-model="showCommentDialog"
      title=""
      width="500px"
      @mds-dialog-dismissed="dissmissDialog"
    >
      <mds-textarea
        v-model="comment"
        label="Please add a comment"
        :required="true"
      />
      <div style="margin-top: 2%;">
        <mds-checkbox
          v-if="operation === 'edit' || workflow.permissions.includes('start_stop')"
          :checked="restartWF === true || restartWF === 'true' || wasWFRestarted === true"
          :disabled="wasWFRestarted || !workflow.permissions.includes('start_stop')"
          @change="restartWF = !restartWF"
          style="padding-bottom: 0% !important;"
        >
          Do you want to restart the workflow?
        </mds-checkbox>
        <span v-if="wasWFRestarted" style="color: red;">Workflow start/stop is under process. Please wait for sometime.</span>
      </div>
      <template #mds-dialog-actions-right>
        <mds-button-container right-aligned>
          <mds-button
            type="button"
            variation="secondary"
            @click="showCommentDialog=!showCommentDialog"
          >
            Cancel
          </mds-button>
          <mds-button
            type="button"
            variation="primary"
            :disabled="!isCommentAdded"
            @click="saveWorkflow()"
          >
            Yes, Proceed
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import MdsModal from '@mds/modal';
import MdsTabs from '@mds/tabs';
import { mapActions, mapGetters } from 'vuex';
import MdsLoader from '@mds/loader';
import MdsTextarea from '@mds/textarea';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import axios from 'axios';
import Cookies from 'js-cookie';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import MdsAlert from '@mds/alert';
import MdsDialog from '@mds/dialog';
// eslint-disable-next-line import/no-extraneous-dependencies
import retry from 'retry';
import CryptoJS from 'crypto-js';
import { Promise } from 'bluebird';
import _ from 'lodash';
import MdsCheckbox from '@mds/checkbox';
import WorkflowDiagram from './Tabs/Diagram/WorkflowDiagram.vue';
import WorkflowInputs from './Tabs/WorkflowInputs.vue';
import WorkflowSchedule from './Tabs/WorkflowSchedule.vue';
import WorkflowDebug from './Tabs/WorkflowDebug.vue';
import WorkflowHistory from './Tabs/WorkflowHistory.vue';
import Manager from './scripts/utils/Manager.js';
import {
  MpDataWorkflow, PermissionSet, TargetBuilder, TaskedTimeout, Target, KeyArrivalDependency,
  TopicDependency, TimeoutType, WorkflowConfig, WorkflowConfigBuilder, ParameterSetGroup, ParameterSet,
} from './scripts/utils/wf';
import Task from './scripts/utils/task.es6';
import MpApi, { _serverModelToParamSetGroup } from './scripts/utils/mpapi.es6';
import Dates from './scripts/utils/dates/dates.es6';
import { getUserName } from '../../utils/authService';
import EventBus from '../../main';

const incompleteRowMessage = 'Please complete the input rows';

export default {
  name: 'WorkflowEdit',
  components: {
    MdsModal,
    MdsTabs,
    WorkflowDiagram,
    WorkflowInputs,
    WorkflowSchedule,
    MdsLoader,
    WorkflowDebug,
    WorkflowHistory,
    MdsTextarea,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsButtonContainer,
    MdsNotification,
    MdsNotificationContainer,
    MdsAlert,
    MdsDialog,
    MdsCheckbox,
  },
  props: {
    index: {
      type: Number,
      default: -2,
    },
    workflowName: {
      type: String,
      default: '',
    },
    operation: {
      type: String,
      default: '',
    },
    workflowId: { type: Number, default: -1 },
  },
  data() {
    return {
      toggleFullscreen: true,
      tabsContent: [{
        text: 'Diagram',
        id: 'diagram',
        active: true,
      },
      {
        text: 'Inputs',
        id: 'inputs',
        active: false,
      },
      {
        text: 'Schedule',
        id: 'schedule',
        active: false,
      },
      {
        text: 'Description',
        id: 'description',
        active: false,
      },
      {
        text: 'Debug',
        id: 'debug',
        active: false,
      },
      {
        text: 'Logs',
        id: 'history',
        active: false,
      },
      ],
      workflowParameters: {},
      showLoader: true,
      formulas: [],
      description: '',
      workflowEditable: false,
      openCreateWorkflow: false,
      openEditWorkflow: false,
      userName: '',
      showError: false,
      errorMessage: '',
      showTargetMissingDialog: false,
      missingDialogErrorMessage: '',
      manager: {},
      inputData: [],
      colTypes: {},
      paramHeaders: [],
      rawPsgModel: [],
      showValidationFailedDialog: false,
      validationFailureMessage: '',
      workflowParams: {},
      workflow: {},
      currentWfName: '',
      unsavedWorkflow: {
        formulas: [],
        psg: {},
      },
      showSaveCancelBtn: false,
      wfNameError: false,
      badFormulaBubbles: [],
      disbaledTextArea: false,
      showDebug: false,
      showCommentDialog: false,
      comment: '',
      statusPayload: {
        // id: crypto.createHash('sha1').update(Date.now().toString()).digest('hex'),
        jsonrpc: '2.0',
        method: 'get_workflow_status_3',
        params: {},
      },
      userWorkflows: [],
      restartWF: false,
      inputsToStart: [],
      inputsToStop: [],
      wasWFRestarted: false,
    };
  },
  computed: {
    ...mapGetters('workflowModule', [
      'getParamStatus',
      'getWorkflowFormulas',
      'getCreateNewWorkflowDetails',
      'getEditableWorkflowDetails',
      'getUserWorkflows',
      'getWorkflowName',
      'getAllParamStatus',
      'getUserWorkflowsData',
    ]),
    ...mapGetters('feedModule', ['getFeedList', 'getFeedDetails', 'getAllFeedRoots']),
    isCommentAdded() {
      return this.comment.length > 0;
    },
  },
  watch: {
    showTargetMissingDialog(value) {
      if (!value) {
        this.toggleFullscreen = false;
      }
    },
    description(value) {
      this.manager._priv.description = value;
      // this.enableSaveCancelBtn();
    },
    workflowParameters: {
      handler(value) {
        this.setInputsData();
        this.manager.inputData = this.inputData;
        this.manager.paramHeaders = this.paramHeaders;
        this.manager.colTypes = this.colTypes;
        this.manager.rawPsgModel = this.workflowParameters.workFlowJobModel.parameterSetGroupModel.parameterSetModels;
        // this.enableSaveCancelBtn();
      },
      deep: true,
    },
    currentWfName(value) {
      if (this.manager._priv) {
        this.manager._priv.name = value;
      }
      // this.enableSaveCancelBtn();
    },
    manager: {
      handler(value) {
        if (value._priv.isModified) {
          this.enableSaveCancelBtn();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
    this.$parent.$on('fetchComplete', (results) => {
      this.wasWFRestarted = false;
      this.$refs.wfDiagram.pageSuccess = 'Successfully start/stop workflow with parameter sets.';
      this.$refs.wfDiagram.showPageSuccess = true;
    });
    EventBus.$on('diagramChanged', this.enableSaveCancelBtn);
  },
  beforeDestroy() {
    this.$parent.$off('fetchComplete'); // Clean up the event listener when component is destroyed
  },
  methods: {
    ...mapActions('workflowModule', [
      'getWorkflowStatus',
      'getFormulasForWorkflow',
      'setUserWorkflow',
      'getParameterSet',
      'setNewlyCreateWorkflow',
      'setCurrentEditableWorkflow',
      'setCurrentWorkflowManager',
      'setCurrentEditParamSet',
      'setCurrentEditWorkflowUI',
      'getAllWorkflowsForDiagram',
      'updateWorkflowFormulas',
      'updateWorkflowByID',
      'addNewFormulaForWorkflow',
      'setWorkflowName',
      'saveParameterSet',
      'createNewWorkflow',
      'updateUserWorkflows',
      'setFormulaDataNodeSet',
      'clearFormulaDataNodeSet',
      'updateUserWorkflowsDetails',
      'setAllParamSet',
    ]),
    ...mapActions('feedModule', ['getUserDatasources', 'getUserFeeds', 'getFeedDetailsByName', 'getFeedRoots']),
    init() {
      this.currentWfName = this.workflowName;
      // if (this.getFeedList.length === 0) {
      //   this.getUserFeeds();
      // }
      if (this.operation === 'edit' || this.operation === 'create') {
        this.disbaledTextArea = false;
      } else {
        this.disbaledTextArea = true;
      }
      if (this.operation === 'edit' || this.operation === 'view') {
        if (this.operation === 'view') {
          this.readOnly = true;
        }
        [this.workflow] = this.getUserWorkflows.filter(wf => wf.id === this.workflowId);
        this.workflowParams = this.getParamStatus(this.workflowId);

        if (this.workflow.wasWFRestarted) {
          this.wasWFRestarted = this.workflow.wasWFRestarted;
          this.restartWF = true;
        }

        this.openEditWorkflow = true;
        this.workflowEditable = this.workflow.permissions.includes('edit');

        if (!((this.workflow.permissions.includes('view') && this.workflow.permissions.includes('start_stop'))
        || this.workflow.permissions.includes('edit'))) {
          this.tabsContent.splice(4, 1);
        }

        if (Object.keys(this.workflowParams).length === 0) {
          this.getWorkflowStatus(this.workflow.id).then((response) => {
            response.data.workFlowJobModel.parameterSetGroupModel.parameterSetModels.forEach(
              (psm) => {
                if (Object.hasOwnProperty.call(psm, 'status')) {
                  response.data.workFlowJobModel.isWFStarted = true;
                } else {
                  response.data.workFlowJobModel.isWFStarted = false;
                }
              },
            );
            this.workflowParameters = this.getParamStatus(this.workflow.id);
          }).catch((error) => {
            this.getParameterSet(this.workflow.psgId).then((response) => {
              this.workflowParameters = {
                workFlowJobModel: {
                  parameterSetGroupModel: response.data,
                },
              };
            }).catch((e) => {
              this.showError = true;
              this.errorMessage = 'Unable to fetch status of workflow. Please try again in sometime';
              this.showError = true;
              this.$emit('closeEdit', this.index);
            }).finally(() => {
              this.setCurrentEditableWorkflow({
                workflowUI: this.workflow,
                paramSet: this.workflowParameters,
                formulae: this.formulas,
                operation: this.operation,
                nodes: {},
                targets: {},
                links: {},
              });
              this.setCurrentEditParamSet({
                data: this.workflowParameters,
                workflowId: this.workflow.id,
              });
              this.makeMarketsManager();
              this.showLoader = false;
            });
          }).finally(() => {
            if (this.getWorkflowFormulas(this.workflow.id)) {
              this.formulas = this.getWorkflowFormulas(this.workflow.id);
              this.setCurrentEditableWorkflow({
                workflowUI: this.workflow,
                paramSet: this.workflowParameters,
                formulae: this.formulas,
                operation: this.operation,
                nodes: {},
                targets: {},
                links: {},
              });
              this.setCurrentEditParamSet({
                data: this.workflowParameters,
                workflowId: this.workflow.id,
              });
              this.makeMarketsManager();
              this.showLoader = false;
            } else {
              this.getFormulasForWorkflow(this.workflow).then(() => {
                this.formulas = this.getWorkflowFormulas(this.workflow.id);
              }).finally(() => {
                this.setCurrentEditableWorkflow({
                  workflowUI: this.workflow,
                  paramSet: this.workflowParameters,
                  formulae: this.formulas,
                  operation: this.operation,
                  nodes: {},
                  targets: {},
                  links: {},
                });
                this.setCurrentEditParamSet({
                  data: this.workflowParameters,
                  workflowId: this.workflow.id,
                });
                this.makeMarketsManager();
                this.showLoader = false;
              });
            }
          });
        } else {
          this.workflowParameters = this.workflowParams;
          if (this.getWorkflowFormulas(this.workflow.id)) {
            this.formulas = this.getWorkflowFormulas(this.workflow.id);
            this.makeMarketsManager();
            this.setCurrentEditableWorkflow({
              workflowUI: this.workflow,
              paramSet: this.workflowParameters,
              formulae: this.formulas,
              operation: this.operation,
              nodes: {},
              targets: {},
              links: {},
            });
            this.setCurrentEditParamSet({
              data: this.workflowParameters,
              workflowId: this.workflow.id,
            });
            this.showLoader = false;
          } else {
            this.getFormulasForWorkflow(this.workflow).then(() => {
              this.formulas = this.getWorkflowFormulas(this.workflow.id);
            }).finally(() => {
              this.setCurrentEditableWorkflow({
                workflowUI: this.workflow,
                paramSet: this.workflowParameters,
                formulae: this.formulas,
                operation: this.operation,
                nodes: {},
                targets: {},
                links: {},
              });
              this.setCurrentEditParamSet({
                data: this.workflowParameters,
                workflowId: this.workflow.id,
              });
              this.makeMarketsManager();
              this.showLoader = false;
            });
          }
        }
      } else if (this.operation === 'create') {
        // eslint-disable-next-line vue/no-mutating-props
        // this.workflowName = 'Untitled 1';
        if (Cookies.get('userInfoVue') !== undefined) {
          this.userName = JSON.parse(Cookies.get('userInfoVue')).userName;
        }
        const emptyWFObj = {
          id: '',
          name: 'Untitled 1',
          creationDate: new Date().getTime(),
          changeDate: new Date().getTime(),
          owner: this.userName,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone.replace('Calcutta', 'Kolkata'),
          permissions: [
            'view',
            'start_stop',
            'edit',
          ],
          description: '',
          psgId: '',
          ui: {
            bubbles: [
            ],
            links: [
            ],
            datasets: [
            ],
            single_vars: [
            ],
            formula_meta: {
              deleted: [],
            },
            psg_id: '',
            isEditedPrev: true,
          },
          targets: [
            {
              name: 'workflow.completion',
              required: true,
              dependencies: [],
              tasks: [],
              timeout: null,
            },
            {
              name: 'workflow_timeout',
              required: false,
              dependencies: [],
              tasks: [],
              timeout: {
                type: 'duration',
                params: {
                  after: '1:00',
                },
                tasks: [
                  {
                    topic: 'workflow.stop',
                    props: {
                      'run.id': '{{RUN_ID}}',
                      'run.result': 'Auto-stop after 1h00m',
                    },
                  },
                ],
              },
            },
          ],
          psgVersion: 0,
          isEditable: true,
        };
        const emptyWorkflowData = {
          workFlowJobModel: {
            cronExpression: '0 0 8 ? * MON-FRI *',
            duration: '1',
            feed: '',
            jobId: '',
            jobName: '',
            lastFireTime: '',
            nextFireTime: '',
            parameterSetGroupModel: {
              creationDate: '',
              id: '',
              name: '',
              parameterSetModels: [
                {
                  description: '',
                  id: '',
                  name: 'unnamed',
                  parameterModels: [
                  ],
                  uuid: '',
                },
              ],
              username: this.userName,
            },
            properties: {
              entry: [
                {
                  key: 'workflow.id',
                  value: '',
                },
                {
                  key: 'parameter-set-group.id',
                  value: '',
                },
              ],
            },
            psgVersion: '0',
            state: 'normal',
            topic: 'workflow.start',
          },
          res: 'OK',
        };

        this.formulas = [];
        this.openCreateWorkflow = true;
        this.setNewlyCreateWorkflow({
          workflowUI: emptyWFObj,
          paramSet: emptyWorkflowData,
          nodes: {},
          targets: {},
          links: {},
        });
        this.setCurrentEditableWorkflow({
          workflowUI: emptyWFObj,
          paramSet: emptyWorkflowData,
          formulae: [],
          operation: 'create',
          nodes: {},
          targets: {},
          links: {},
        });
        this.setCurrentEditParamSet({
          data: emptyWorkflowData,
          workflowId: 0,
        });
        this.workflowParameters = this.getCreateNewWorkflowDetails.paramSet;
        // eslint-disable-next-line vue/no-mutating-props
        this.workflow = this.getCreateNewWorkflowDetails.workflowUI;
        const newWfConfigBulder = new WorkflowConfigBuilder('Untitled 1');
        newWfConfigBulder.timezone(Intl.DateTimeFormat().resolvedOptions().timeZone.replace('Calcutta', 'Kolkata'));
        const wfConfig = newWfConfigBulder.build();
        this.manager = new Manager();
        this.manager._priv.name = 'Untitled 1';
        this.manager._priv.config = wfConfig;
        this.manager._priv.updateParameterSetData = {
          currentPsgVersion: this.workflowParameters.workFlowJobModel.psgVersion,
          add: [],
          update: [],
          psgToRename: [],
          delete: {
            psgIds: [],
            paramIds: [],
          },
        };
        this.manager.timezone(Intl.DateTimeFormat().resolvedOptions().timeZone.replace('Calcutta', 'Kolkata'));
        this.setCurrentWorkflowManager(this.manager);
        this.setInputsData();
        this.showLoader = false;
      }
    },
    makeMarketsManager() {
      try {
        const perm = new PermissionSet(this.workflow.permissions);
        const wfData = new MpDataWorkflow(parseInt(this.workflow.id, 10), this.workflow.name, this.workflow.owner,
          this.workflow.creationDate, this.workflow.changeDate, this.workflow.timeZone, '', this.workflow.description);
        const targetBuilder = new TargetBuilder('builder');
        const targets = this.workflow.targets.map((target) => {
          targetBuilder.name(target.name);
          targetBuilder.isRequired(target.required);
          const dependencies = target.dependencies.map((dependency) => {
            if (Object.hasOwnProperty.call(dependency, 'type') && dependency.type === 'kaw_sub') {
              const {
                feed, keys, roots, columns, scope, type, timeout,
              } = dependency;
              return new KeyArrivalDependency(feed, keys, roots, columns, null, scope);
            }
            const { topic, props, scope } = dependency;
            return new TopicDependency(topic, props, scope);
          });
          targetBuilder.dependencies(dependencies);
          const tasks = target.tasks.map((task) => {
            const { topic, props } = task;
            return new Task(topic, props);
          });
          targetBuilder.tasks(tasks);
          if (Object.hasOwnProperty.call(target, 'timeout')) {
            const tasks1 = target.timeout.tasks.map((task) => {
              const { topic, props } = task;
              return new Task(topic, props);
            });
            const type = target.timeout.type === 'duration' ? TimeoutType.DURATION : TimeoutType.TIME_OF_DAY;
            targetBuilder.timeout(new TaskedTimeout(type, target.timeout.params, tasks1));
          }
          // TODO: User actions
          return targetBuilder.build();
        });
        const wfSchAndPsg = MpApi.GetWorkflowParameterSetGroup.prototype.$formatResponse(this.workflowParameters);
        this.workflow.ui.bubbles.forEach((bubble) => {
          if (bubble.type === 'publish') {
            bubble.type = 'save';
          }
          bubble.coord = { x: bubble.coord.x, y: bubble.coord.y };
        });
        const wfConfig = new WorkflowConfig(wfData, perm, targets, this.workflow.ui, null, this.workflow.psgId);
        this.manager = new Manager().load(wfConfig, wfSchAndPsg, this.formulas, this);
        this.manager._priv.updateParameterSetData = {
          currentPsgVersion: this.workflowParameters.workFlowJobModel.psgVersion,
          add: [],
          update: [],
          psgToRename: [],
          delete: {
            psgIds: [],
            paramIds: [],
          },
        };
        this.manager._priv.correctionDays = this.workflowParameters.workFlowJobModel.correctionDays || 0;
        this.setCurrentWorkflowManager(this.manager);
        this.clearFormulaDataNodeSet();
        this.setInputsData();
        this.manager.inputData = this.inputData;
        this.manager.paramHeaders = this.paramHeaders;
        this.manager.colTypes = this.colTypes;
        this.manager.rawPsgModel = this.rawPsgModel;
        this.description = this.manager._priv.description;
      } catch (error) {
        console.error(error);
        this.missingDialogErrorMessage = error;
        this.showTargetMissingDialog = true;
      }
    },
    chnageDesc() {
      if (this.disbaledTextArea) {
        return;
      }
      this.manager._priv.description = this.description;
      this.enableSaveCancelBtn();
    },
    setInputsData() {
      const oldRows = [];
      if (this.manager && this.manager._priv && this.manager._priv.paramSetGroup
        && Object.hasOwn(this.manager._priv.paramSetGroup, '_paramsets')) {
        this.manager._priv.paramSetGroup._paramsets.forEach((p) => {
          oldRows.push(p._name);
        });
      }

      this.inputData = [];
      this.colTypes = {};
      this.paramHeaders = [];
      if (this.workflowParameters.workFlowJobModel.parameterSetGroupModel.parameterSetModels.length > 0) {
        this.rawPsgModel = this.workflowParameters.workFlowJobModel.parameterSetGroupModel.parameterSetModels;
        this.workflowParameters.workFlowJobModel.parameterSetGroupModel.parameterSetModels.forEach((param, index) => {
          const inputMap = {};
          const rowData = {
            name: param.name,
            rowName: param.name,
            description: param.description,
          };
          param.parameterModels.forEach((element) => {
            if (element.propKey.startsWith('udef.ds.')) {
              const colName = element.propKey.replace('udef.ds.', '').split('.')[0];
              const type = element.propKey.replace('udef.ds.', '').split('.')[1];
              const value = element.propValue;
              if (!Object.prototype.hasOwnProperty.call(inputMap, colName)) {
                inputMap[colName] = {};
              }
              if (type === 'cols' || type === 'key') {
                inputMap[colName][type] = JSON.parse(value);
              } else {
                inputMap[colName][type] = value.replace(/['"]+/g, '');
              }
            } else if (element.propKey.startsWith('udef.var.')) {
              const colName = element.propKey.replace('udef.var.', '');
              const value = element.propValue.replace(/['"]+/g, '');
              inputMap[colName] = { value };
              inputMap[colName]['isValueType'] = true;
            }
          });
          Object.keys(inputMap).forEach((key, colIdx) => {
            if (Object.hasOwnProperty.call(inputMap[key], 'key') && Array.isArray(inputMap[key]['key'])) {
              inputMap[key]['isForwardCurve'] = true;
              inputMap[key]['type'] = 'forwardCurve';
            } else if (Object.hasOwnProperty.call(inputMap[key], 'key')) {
              inputMap[key]['isForwardCurve'] = false;
              inputMap[key]['type'] = 'timeSeries';
            } else {
              inputMap[key]['isForwardCurve'] = false;
              const variableTypeInfo = this.determineVariableType(inputMap[key]['value']);
              inputMap[key]['type'] = variableTypeInfo.type;
            }
            rowData[key] = inputMap[key];
            this.colTypes[key] = inputMap[key]['type'];
            if (index === 0) {
              if (this.manager.psgColHeader) {
                const colName = this.manager.psgColHeader(colIdx + 2);

                if (colName) {
                  const colType = new RegExp(/\$.+ \((.*)\)/gm).exec(colName)[1];
                  let bgColor;
                  if (colType === 'Data') {
                    bgColor = '#f2e6f0';
                    inputMap[key]['data'] = this.processInputData(colType, key);
                  } else if (colType === 'Publish') {
                    bgColor = '#fef9e5';
                  } else {
                    bgColor = '#e5f7eb';
                  }
                  this.paramHeaders.push({
                    text: colName,
                    style: {
                      width: '150px',
                      'background-color': bgColor,
                    },
                  });
                }
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (this.manager.psgColHeader) {
                const colName = this.manager.psgColHeader(colIdx + 2);
                if (colName) {
                  const colType = new RegExp(/\$.+ \((.*)\)/gm).exec(colName)[1];
                  if (colType === 'Data') {
                    inputMap[key]['data'] = this.processInputData(colType, key);
                  } else if (colType === 'Publish') {}
                }
              }
            }
          });
          rowData.copiedRowName = `Copy_of_${rowData.name}`;
          rowData.checked = false;
          rowData.isNewRow = !oldRows.includes(rowData.name);
          Vue.set(this.inputData, index, rowData);
        });
      }
    },
    processInputData(colType, key) {
      if (colType === 'Data') {
        for (let i = 0; i < this.manager._priv.datasets.length; i++) {
          const element = this.manager._priv.datasets[i];
          if (element._name === key) {
            return Object.assign({}, element._bbl._data);
          }
        }
      }
      return '';
    },
    determineVariableType(value) {
      let varType = null;
      let varValue = null;
      try {
        varType = typeof JSON.parse(value);
        varValue = JSON.stringify(JSON.parse(value));
      } catch {
        if (/\d{4}-\d{2}-\d{2}/gm.test(value)) {
          varValue = new Date(value);
          // eslint-disable-next-line eqeqeq
          if (varValue == 'Invalid Date') {
            varType = 'string';
            varValue = value;
          } else {
            varType = 'date';
          }
        } else {
          varType = 'string';
        }
      }
      return { type: varType, value: varValue };
    },
    checkKeysPopulated(feedKeys) {
      if (Object.keys(feedKeys).length > 0) {
        const keys = Object.keys(feedKeys);
        return !(keys.filter(key => feedKeys[key].length === 0).length > 0);
      }
      return false;
    },
    allParametersAdded(paramData) {
      const {
        type, feed, source, columns, keys, roots, value,
      } = paramData;

      if (type === 'timeSeries') {
        if (feed.length === 0 || source.length === 0 || columns.length === 0 || !this.checkKeysPopulated(keys)) {
          return false;
        }
      } else if (type === 'forwardCurve') {
        if (feed.length === 0 || source.length === 0 || columns.length === 0 || roots.length === 0) {
          return false;
        }
      } else if (['number', 'boolean', 'date', 'object'].includes(type)) {
        if (value.length === 0) {
          return false;
        }
      }
      return true;
    },
    checkRowCompleted(row) {
      const keys = Object.keys(row);
      const objKeys = keys.filter(key => typeof row[key] === 'object' && !key.startsWith('_')).map(key => row[key]);
      const areAllColumnsFilled = objKeys.filter(col => this.allParametersAdded({
        type: col.type, feed: col.feed, source: col.source, columns: col.cols, keys: col.key, roots: col.key, value: col.value,
      }));
      return Object.keys(this.colTypes).length === areAllColumnsFilled.length && row.name.length > 0;
    },
    convertRowDataToApiData(row) {
      const psg = {};
      psg.name = row.name;
      psg.description = row.description;
      psg.parameterModels = [];

      Object.keys(this.colTypes).forEach((col) => {
        const colData = row[col];
        const prop = { propKey: '', propValue: '' };
        if (['timeSeries', 'forwardCurve'].includes(colData.type)) {
          prop.propKey = `udef.ds.${col}.cols`;
          prop.propValue = JSON.stringify(colData.cols);
          psg.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${col}.feed`;
          prop.propValue = colData.feed;
          psg.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${col}.key`;
          prop.propValue = JSON.stringify(colData.key);
          psg.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${col}.prov`;
          prop.propValue = colData.prov;
          psg.parameterModels.push(JSON.parse(JSON.stringify(prop)));

          prop.propKey = `udef.ds.${col}.source`;
          prop.propValue = colData.source;
          psg.parameterModels.push(JSON.parse(JSON.stringify(prop)));
        } else {
          prop.propKey = `udef.var.${col}`;
          if (['boolean', 'date', 'string'].includes(colData.type)) {
            prop.propValue = colData.value;
          } else {
            prop.propValue = JSON.stringify(colData.value);
          }
          psg.parameterModels.push(JSON.parse(JSON.stringify(prop)));
        }
      });
      return psg;
    },
    isParamEmpty(param) {
      if (Object.hasOwn(param, 'isValueType') && param.isValueType) {
        return param.value.length === 0;
      } if (Object.hasOwn(param, 'type') && ['timeSeries', 'forwardCurve'].includes(param.type)) {
        if (JSON.stringify(param.cols).length === 0 || param.feed.length === 0 || param.prov.length === 0
          || JSON.stringify(param.key).length === 0 || param.source.length === 0) {
          return (JSON.stringify(param.cols).length === 0 || param.feed.length === 0 || param.prov.length === 0
          || JSON.stringify(param.key).length === 0 || param.source.length === 0);
        }
      }
      return true;
    },
    checkIfRowhasData(row) {
      const rowVars = Object.keys(row).filter(key => !key.startsWith('_') && typeof row[key] === 'object')
        .filter(key => !['isNewRow', 'index'].includes(key)).map(key => row[key]);
      return rowVars.some(v => !this.isParamEmpty(v));
    },
    validateBeforeSubmit() {
      if (this.wfNameError) {
        return false;
      }
      if (this.inputData.length === 0) {
        this.validationFailureMessage = 'Workflow has no input set.';
        this.showValidationFailedDialog = true;
        this.showLoader = false;
        return false;
      }
      const newRowsToBeAdded = this.inputData.filter(row => row.name.length > 0 && row.isNewRow);
      const areNewRowComplete = newRowsToBeAdded.every(row => this.checkRowCompleted(row));
      let psgToAdd = this.manager._priv.updateParameterSetData.add;

      if (areNewRowComplete) {
        const addedRowNames = psgToAdd.map(row => row.name);
        newRowsToBeAdded.forEach((row) => {
          // if new row already present in psgToAdd remove and add again
          if (addedRowNames.includes(row.name)) {
            psgToAdd = psgToAdd.filter(addedRow => addedRow.name !== row.name);
          }
          psgToAdd.push(this.convertRowDataToApiData(row));
        });
      }

      const rowsToBeValidated = this.inputData.filter(row => !row.isNewRow && row.name.length > 0);
      const areOldRowsComplete = rowsToBeValidated.every(row => this.checkRowCompleted(row));
      const rowMd5HashMap = {};
      this.inputData.filter(row => row.name.length > 0).some((row) => {
        const temp = _((JSON.parse(JSON.stringify(row)))).toPairs().sortBy(0).fromPairs()
          .value();
        delete temp.name;
        delete temp.rowName;
        delete temp.copiedRowName;
        delete temp.description;
        delete temp.index;
        delete temp.checked;
        delete temp.visible;
        delete temp.isNewRow;
        Object.keys(temp).filter(key => key.startsWith('_')).forEach((key) => {
          delete temp[key];
        });

        Object.keys(temp).forEach((key) => {
          if (Object.hasOwn(temp[key], 'data')) {
            delete temp[key].data;
          }
        });

        if (Object.keys(rowMd5HashMap).includes(CryptoJS.MD5(JSON.stringify(temp)).toString())) {
          this.validationFailureMessage = `Duplicate Rows Encountered ${row.name}
            - ${rowMd5HashMap[CryptoJS.MD5(JSON.stringify(temp))]}`;
          this.showValidationFailedDialog = true;
          this.showLoader = false;
          return true;
        }
        rowMd5HashMap[CryptoJS.MD5(JSON.stringify(temp)).toString()] = row.name;
        return false;
      });

      if (this.showValidationFailedDialog) {
        return false;
      }

      if (!areNewRowComplete || !areOldRowsComplete) {
        this.showValidationFailedDialog = true;
        this.showLoader = false;
        this.validationFailureMessage = incompleteRowMessage;
        return false;
      }
      return true;
    },
    setActiveItem(event) {
      this.tabsContent.forEach((item) => {
        if (item.id === event.currentTarget.id) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    wfNameErrorFun(val) {
      this.wfNameError = val;
    },
    editWorkflowName(wfName) {
      if (wfName === '') {
        this.currentWfName = 'Untitled 1';
      }
      this.currentWfName = wfName;
    },
    rowVarToParameter(res, val, k, index) {
      if (['cols', 'key', 'source', 'prov', 'feed'].includes(k)) {
        const temp = {};
        temp['propKey'] = `${res[0].propKey}.${k}`;
        temp['propValue'] = typeof val !== 'string' ? JSON.stringify(val) : val;
        res.push(temp);
      }
    },
    cellToParams(result, value, key) {
      if (typeof value === 'object' && ['timeSeries', 'forwardCurve'].includes(value.type)) {
        const acc = [{ propKey: `udef.ds.${key}` }];
        _.transform(value, this.rowVarToParameter, acc);
        acc.shift();
        result.parameterModels = _.concat(result.parameterModels, acc);
      } else if (typeof value === 'object' && Object.hasOwn(value, 'type')) {
        const temp = {};
        temp['propKey'] = `udef.var.${key}`;
        temp['propValue'] = value.value;
        result.parameterModels.push(temp);
      }
    },
    inputDataToServerModel() {
      const temp = this.manager.inputData.filter(row => row.name.length > 0).map(row => _.transform(row, this.cellToParams, {
        uuid: null,
        name: row.name,
        description: row.description,
        parameterModels: [],
      }));
      return temp;
    },
    saveWorkflow() {
      if (!this.isCommentAdded) {
        return;
      }
      this.showCommentDialog = false;
      if (!this.validateBeforeSubmit()) {
        return;
      }
      const errors = [];
      // const vueVars = { loader: this.showLoader };
      this.manager.save(errors, this);
      if (errors.length > 0) {
        this.errorMessage = errors.join('\n');
        this.showError = true;
      }
    },
    async saveFormula(saveOper) {
      this.unsavedWorkflow.formulas = [];
      let seq = 0;
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const i in saveOper._formulas) {
        const formula = saveOper._formulas[i];
        const cleanName = saveOper._name.replace(new RegExp('\\W+', 'g'), '_');
        const prefix = `wfgen_${ cleanName }_${
          Dates.getFormatter('yyyyMMddTHHmmss', false)(new Date()) }_`;

        const payload = {
          name: prefix + (++seq),
          type: 'JS',
          formula: formula._code,
        };
        // eslint-disable-next-line no-await-in-loop
        const formulaResp = await this.updateWorkflowFormulas(payload);
        if (formulaResp.status === 200) {
          formula._uuid = formulaResp.data.uuid;
          this.updateFormulaUUID(formula);
          if (this.operation !== 'create') {
            // add formula in vuex
            this.addNewFormulaForWorkflow({ formula: formulaResp.data, workflowId: this.workflow.id });
          } else {
            this.unsavedWorkflow.formulas.push(formulaResp.data);
          }
        }
      }
    },
    async fetchWorkflow(workflowId) {
      const resp = await this.getWorkflowStatus(workflowId || this.workflowId);
      resp.data.workFlowJobModel.parameterSetGroupModel.parameterSetModels.forEach(
        (psm) => {
          if (Object.hasOwnProperty.call(psm, 'status')) {
            resp.data.workFlowJobModel.isWFStarted = true;
          } else {
            resp.data.workFlowJobModel.isWFStarted = false;
          }
        },
      );
      if (resp.status === 200 || resp.status === 304) {
        this.workflowParameters = this.getParamStatus(workflowId || this.workflowId);
        this.rawPsgModel = this.workflowParameters.workFlowJobModel.parameterSetGroupModel.parameterSetModels;
      } else {
        // this.$set(this.workflowParams, index, { workFlowJobModel: { parameterSetGroupModel: { parameterSetModels: [] } } });
      }
    },
    async createNewParameterSet() {
      // Get the current date and time
      const currentDate = new Date();

      // Extract individual components
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');

      const payload = {
        name: `${this.workflow.name }_${year}-${month}-${day}T${hours}:${minutes}:${seconds}`,
        parameterSetModels: this.inputDataToServerModel(),
      };

      try {
        const response = await this.saveParameterSet(payload);
        this.unsavedWorkflow.psg = response.data;
        this.manager._priv.paramSetGroup = response.data;
        this.manager._priv.psgBufUpdSeqSaved = this.manager._priv.psgBufUpdSeq; // Indicate that we match the server.
      } catch (e) {
        console.error(e);
      }
    },
    async updateInputs() {
      if (this.operation === 'create') {
        await this.createNewParameterSet();
        return;
      }
      const payload = this.manager._priv.updateParameterSetData;
      try {
        const resp = await axios.post(`/api/workflows/${this.workflow.id}/inputs`, payload);
      } catch (e) {
        // show error
      } finally {
        this.manager._priv.updateParameterSetData.add = [];
        this.manager._priv.updateParameterSetData.update = [];
        this.manager._priv.updateParameterSetData.delete.psgIds = [];
        this.manager._priv.updateParameterSetData.delete.paramIds = [];
        this.manager._priv.updateParameterSetData.psgToRename = [];
      }
    },
    async saveSchedule(wfConfig, cronExpression, psgId) {
      const payload = { psgId, cron: cronExpression, timeZone: wfConfig.timeZone };
      const operation = retry.operation({
        retries: 3,
        factor: 3,
        minTimeout: 1 * 1000,
        maxTimeout: 60 * 1000,
        randomize: true,
      });

      return new Promise((resolve, reject) => {
        operation.attempt(async (currentAttempt) => {
          try {
            await axios.post(`/api/workflows/${wfConfig.id}/updateSchedule`, payload);
            resolve();
          } catch (e) {
            operation.retry(e);
          }
        });
      });
    },
    updateFormulaUUID(formulaContext) {
      const priv = this.manager._priv;
      const newUuid = formulaContext.getUuid();
      /** @type {{formula: Formula, bubble: Bubble}} */
      const localCtx = formulaContext.getContext();
      const { formula } = localCtx;
      const { bubble } = localCtx;
      const prevUuid = formula.id;

      formula.id = newUuid;
      formula.isNew = false;
      formula.isModified = false;

      // Reset bubble's pointer from new formula uuid.
      bubble.data('formula_id', newUuid);

      delete priv.formulas[prevUuid];
      priv.formulas[newUuid] = formula;
    },
    async saveNewWorkflow(wfConfig) {
      try {
        const coordMap = {};
        this.workflow.ui.bubbles.forEach((b) => {
          coordMap[b.id] = b.coord;
        });
        wfConfig.ui.bubbles.forEach((b) => {
          b.coord = coordMap[b.id];
        });
        wfConfig.psgId = this.unsavedWorkflow.psg.id;
        wfConfig.ui.psg_id = this.unsavedWorkflow.psg.id;
        const wfResp = await this.createNewWorkflow(wfConfig);
        if (wfResp.status === 200) {
          this.manager._priv = wfResp.data;
          this.manager._priv.cronExpr = wfResp.data.ui.cron_expr;
          const wfId = wfResp.data.id;
          await this.updateUserWorkflows({
            username: getUserName(),
            workflowsToAdd: [wfId],
            workflowsToRemove: [],
          });
          // add formula in vuex
          for (let i = 0; i < this.unsavedWorkflow.formulas.length; i++) {
            const formula = this.unsavedWorkflow.formulas[i];
            this.formulas.push(formula);
            this.addNewFormulaForWorkflow({ formula, workflowId: wfId });
          }
          wfResp.data.workflowNameToShow = `${wfResp.data.id} - ${wfResp.data.name}`;
          wfResp.data.enableActionButtons = true;
          wfResp.data.expanded = false;
          wfResp.data.toggleEditView = false;
          wfResp.data.toggleStartStop = false;
          wfResp.data.toggleChangeOwner = false;
          wfResp.data.toggleChangePermission = false;
          wfResp.data.toggleDelete = false;
          wfResp.data.toggleRemove = false;
          wfResp.data.toggleWorkflowMigration = false;
          wfResp.data.isEditable = !!wfResp.data.permissions.includes('edit');
          wfResp.data.isStartStopPermission = !!wfResp.data.permissions.includes('start_stop');
          this.workflow = wfResp.data;
          this.manager._priv = wfResp.data;
          this.manager._priv.cronExpr = wfResp.data.ui.cron_expr;
          return wfResp.data;
        }
        return null;
      } catch (e) {
        // TODO: log errors
        return null;
      }
    },
    async saveWorkflowConfig(wfConfig) {
      try {
        const coordMap = {};
        this.workflow.ui.bubbles.forEach((b) => {
          coordMap[b.id] = b.coord;
        });
        wfConfig.ui.bubbles.forEach((b) => {
          b.coord = coordMap[b.id];
        });
        wfConfig.comment = this.comment;
        const wfResp = await this.updateWorkflowByID(wfConfig);
        if (wfResp.status === 200) {
          wfResp.data.workflowNameToShow = `${wfResp.data.id} - ${wfResp.data.name}`;
          wfResp.data.enableActionButtons = true;
          wfResp.data.expanded = false;
          wfResp.data.toggleEditView = false;
          wfResp.data.toggleStartStop = false;
          wfResp.data.toggleChangeOwner = false;
          wfResp.data.toggleChangePermission = false;
          wfResp.data.toggleDelete = false;
          wfResp.data.toggleRemove = false;
          wfResp.data.toggleWorkflowMigration = false;
          wfResp.data.isEditable = !!wfResp.data.permissions.includes('edit');
          wfResp.data.isStartStopPermission = !!wfResp.data.permissions.includes('start_stop');
          this.workflow = wfResp.data;
          this.manager._priv = wfResp.data;
          this.manager._priv.cronExpr = wfResp.data.ui.cron_expr;
          return wfResp.data;
        }
        return null;
      } catch (e) {
        // TODO: log errors
        return null;
      }
    },
    async resetWorkflowData(workflowId) {
      // eslint-disable-next-line vue/no-mutating-props
      this.workflowId = workflowId;
      if (this.workflowId) {
        const freshWFDeatils = await axios.get(`/api/workflows/${workflowId}/getWorkflowByID`);
        // eslint-disable-next-line vue/no-mutating-props
        this.workflowName = freshWFDeatils.data.name;
        freshWFDeatils.data.workflowNameToShow = `${freshWFDeatils.data.id} - ${freshWFDeatils.data.name}`;
        freshWFDeatils.data.enableActionButtons = true;
        freshWFDeatils.data.expanded = false;
        freshWFDeatils.data.toggleEditView = false;
        freshWFDeatils.data.toggleStartStop = false;
        freshWFDeatils.data.toggleChangeOwner = false;
        freshWFDeatils.data.toggleChangePermission = false;
        freshWFDeatils.data.toggleDelete = false;
        freshWFDeatils.data.toggleRemove = false;
        freshWFDeatils.data.toggleWorkflowMigration = false;
        freshWFDeatils.data.isEditable = !!freshWFDeatils.data.permissions.includes('edit');
        freshWFDeatils.data.isStartStopPermission = !!freshWFDeatils.data.permissions.includes('start_stop');
        this.userWorkflows = this.getUserWorkflowsData;
        let flag = 0;
        this.userWorkflows.forEach((wf) => {
          if (wf.id === freshWFDeatils.data.id) {
            if (this.restartWF) {
              freshWFDeatils.data.wasWFRestarted = true;
            }
            freshWFDeatils.data.expanded = wf.expanded ? wf.expanded : false;
            this.setUserWorkflow(freshWFDeatils.data);
            this.setCurrentEditWorkflowUI(freshWFDeatils.data);
            flag = 1;
          }
        });
        if (flag === 0) {
          freshWFDeatils.data.expanded = false;
          this.userWorkflows.push(freshWFDeatils.data);
          this.updateUserWorkflowsDetails(this.userWorkflows);
          this.setCurrentEditWorkflowUI(freshWFDeatils.data);
        }
        this.workflow = freshWFDeatils.data;
        const resp = await this.getWorkflowStatus(workflowId);

        if (this.restartWF && !this.wasWFRestarted) {
          resp.data.workFlowJobModel.parameterSetGroupModel.parameterSetModels.forEach(
            (psm) => {
              if (Object.hasOwnProperty.call(psm, 'status')) {
                this.inputsToStop.push(psm.status.run_id);
                resp.data.workFlowJobModel.isWFStarted = true;
              } else {
                resp.data.workFlowJobModel.isWFStarted = false;
              }
              this.inputsToStart.push({
                workflow_id: workflowId,
                param_set_id: psm.id,
              });
            },
          );

          if (this.inputsToStop.length > 0 && resp.data.workFlowJobModel.isWFStarted) {
            const params = this.inputsToStop.map(input => input);
            await axios.post('/api/workflows/stopWorkflowInput', { params }).then(() => {
              this.inputsToStop = [];
            });
          }

          if (this.inputsToStart.length > 0) {
            Promise.map(this.inputsToStart, input => axios.post('/api/workflows/startWorkflowInput', {
              params: {
                workflow_id: input.workflow_id,
                param_set_id: input.param_set_id,
              },
            }), { concurrency: 2 }).then(async () => {
              this.inputsToStop = [];
              this.inputsToStart = [];
              await this.fetchWorkflow(workflowId);

              [this.workflow] = this.getUserWorkflows.filter(wf => wf.id === this.workflowId);
              this.workflow.wasWFRestarted = false;
              this.setUserWorkflow(this.workflow);

              this.restartWF = false;
              this.wasWFRestarted = false;
              this.$refs.wfDiagram.pageSuccess = 'Workflow restarted sucessfully.';
              this.$refs.wfDiagram.showPageSuccess = true;
            });

            const paramsets = this.getAllParamStatus;
            if (paramsets[0]) {
              delete paramsets[0];
            }
            this.wasWFRestarted = true;
            paramsets[workflowId] = resp.data;
            this.workflowParameters = resp.data;
            this.rawPsgModel = this.workflowParameters.workFlowJobModel.parameterSetGroupModel.parameterSetModels;
            this.setAllParamSet(paramsets);

            this.$emit('resetWorkflowEdit', workflowId, resp.data);

            await this.getFormulasForWorkflow(this.workflow).then(() => {
              this.formulas = this.getWorkflowFormulas(this.workflow.id);
            }).finally(() => {
              this.setCurrentEditableWorkflow({
                workflowUI: this.workflow,
                paramSet: this.workflowParameters,
                formulae: this.formulas,
                operation: this.operation,
                nodes: {},
                targets: {},
                links: {},
              });
            });
            await this.makeMarketsManager();
          }
        } else {
          const paramsets = this.getAllParamStatus;
          if (paramsets[0]) {
            delete paramsets[0];
          }
          paramsets[workflowId] = resp.data;
          this.workflowParameters = resp.data;
          this.rawPsgModel = this.workflowParameters.workFlowJobModel.parameterSetGroupModel.parameterSetModels;
          this.setAllParamSet(paramsets);

          this.$emit('resetWorkflowEdit', workflowId, resp.data);

          await this.getFormulasForWorkflow(this.workflow).then(() => {
            this.formulas = this.getWorkflowFormulas(this.workflow.id);
          }).finally(() => {
            this.setCurrentEditableWorkflow({
              workflowUI: this.workflow,
              paramSet: this.workflowParameters,
              formulae: this.formulas,
              operation: this.operation,
              nodes: {},
              targets: {},
              links: {},
            });
          });
          await this.makeMarketsManager();
        }
      }
      this.showLoader = false;
      this.showSaveCancelBtn = false;
      // this.init();
    },
    hideError() {
      this.showError = false;
      if (document.getElementById('showValidationError')) {
        document.getElementById('showValidationError').style.display = 'none';
        document.getElementById('showValidationError').style.opacity = '0';
      }
    },
    dissmissDialog() {
      if (document.getElementById('showCopyDialog')) {
        document.getElementById('showCopyDialog').style.display = 'none';
        document.getElementById('showCopyDialog').style.opacity = '0';
      } else if (document.getElementById('showValidationError')) {
        document.getElementById('showValidationError').style.display = 'none';
        document.getElementById('showValidationError').style.opacity = '0';
      }
    },
    cancelModel() {
      this.$emit('closeEdit', this.index, 'cancel');
    },
    closeModel() {
      this.$emit('closeEdit', this.index, 'close');
    },
    enableSaveCancelBtn() {
      this.showSaveCancelBtn = true;
    },
    openCommentDialoge() {
      if (this.wfNameError) {
        return;
      }
      this.showCommentDialog = true;

      if (this.operation === 'edit') {
        [this.workflow] = this.getUserWorkflows.filter(wf => wf.id === this.workflowId);
        if (this.workflow.wasWFRestarted) {
          this.wasWFRestarted = this.workflow.wasWFRestarted;
        } else {
          this.wasWFRestarted = false;
        }
      }
    },
    showDiagramSuccess() {
      this.wasWFRestarted = false;
      this.restartWF = false;
      this.inputsToStop = [];
      this.inputsToStart = [];
      this.$refs.wfDiagram.pageSuccess = 'Successfully start/stop workflow with parameter sets.';
      this.$refs.wfDiagram.showPageSuccess = true;
    },
  },
};
</script>

<style>
  #fullscreen {
    cursor: auto;
  }

  #fullscreen .mds-modal__container___Mcd-ui{
    height: 100% !important;
    width: 100% !important;
    min-height: 100% !important;
    min-width: 100% !important;
  }

  #fullscreen .mds-section___Mcd-ui{
    min-height: 100% !important;
    position: relative;
  }
  #fullscreen .mds-section__content___Mcd-ui {
    padding-top: 3px;
  }
  #fullscreen .node-diagram svg {
    height: 70vh;
    position: relative;
    width: 100%;

  }
  @media screen and (max-width: 768px)  {
    #fullscreen .node-diagram svg {
      height: 70vh;
    }
  }
  @media screen and (max-width: 1200px)  {
    #fullscreen .node-diagram svg {
      height: 80vh;
    }
  }

  #fullscreen .node-diagram svg button svg {
    height: 2vh;
  }

  #fullscreen .mds-notification___Mcd-ui {
    box-shadow: none;
  }
  /* #showCopyDialog .mds-directive--overlay__v3 {
    display: none !important;
    opacity: 0 !important;
  } */

  #fullscreen .mds-section__actions___Mcd-ui {
    display: none;
  }
</style>
