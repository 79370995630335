<template>
  <div>
    <!-- eslint-disable-next-line vue/no-lone-template -->
    <template>
      <mds-form>
        <mds-row style="margin-top: 0.3rem;">
          <mds-col :cols="4">
            <mds-combo-box
              v-model="selectedInput"
              label="Input Name"
              :data-set="getInputNames()"
            />
          </mds-col>
          <mds-col :cols="8">
            <mds-combo-box
              v-model="selectedPreviewVars"
              label="Preview Variables"
              multiple
              :data-set="getInputVarsList()"
            />
          </mds-col>
        </mds-row>
        <mds-row style="margin-top: 1rem;">
          <mds-col :cols="2">
            <mds-field-set
              variation="radio-group"
              legend=""
            >
              <div style="margin-top: 1rem;">
                <mds-radio-button
                  v-model="selectedDateType"
                  name="Latest Available"
                  value="latest_available"
                  label="Latest Available"
                  :checked="selectedDateType == 'latest_available'"
                />
                <mds-radio-button
                  v-model="selectedDateType"
                  name="Fixed"
                  value="Fixed"
                  label="Fixed"
                />
              </div>
            </mds-field-set>
          </mds-col>
          <mds-col :cols="3">
            <mds-date-picker
              v-model="selectedDate"
              :min-max-dates="{ min: new Date(2015, 0, 1), max: new Date(2025, 8, 15) }"
              placeholder="m/d/yyyy"
              label="Date"
              :disabled="selectedDateType!='Fixed'"
            />
          </mds-col>
          <mds-col
            :cols="2"
            style="margin-top: 1.9rem;"
          >
            <mds-button
              variation="primary"
              @click.prevent="refreshFormula()"
            >
              Refresh
            </mds-button>
          </mds-col>
        </mds-row>
      </mds-form>
      <mds-loader
        v-if="showPreviewLoader"
        size="medium"
        aria-label="Small Loader"
      />
      <div
        v-if="!showError"
        class="prev-res-table"
      >
        <mds-table
          v-if="formulaPreviewData"
          row-hover
        >
          <mds-thead>
            <mds-th class="formula-prev-result">
              Date
            </mds-th>
            <template v-for="(_, index) in formulaPreviewData[0].slice(1)">
              <mds-th
                :key="index"
                class="formula-prev-result"
                style="text-align: center;"
              >
                {{ index }}
              </mds-th>
            </template>
          </mds-thead>
          <mds-tbody>
            <template v-for="(dataRow, index) in formulaPreviewData">
              <mds-tr :key="index">
                <template v-for="(data, dIndex) in dataRow">
                  <mds-td
                    :key="dIndex"
                    style="text-align: center;"
                    :class="getClassesForPreviewData(data)"
                  >
                    {{ data }}
                  </mds-td>
                </template>
              </mds-tr>
            </template>
            <mds-tr v-if="formulaPreviewDataOccurences">
              <mds-td>
                <b>{{ formulaPreviewDataOccurences }}</b> Occurences
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
      </div>
      <div
        v-else
        class="prev-res-table"
      >
        <mds-alert
          variation="error"
          title="Preview Error"
          persistent
        >
          <pre class="formula-test-error">
            {{ errorMessage }}
        </pre>
        </mds-alert>
      </div>
    </template>
  </div>
</template>

<script>
import MdsForm from '@mds/form';
import { MdsButton } from '@mds/button';
import MdsComboBox from '@mds/combo-box';
import MdsRadioButton from '@mds/radio-button';
import MdsFieldSet from '@mds/fieldset';
import MdsDatePicker from '@mds/date-picker';
import MdsAlert from '@mds/alert';
import { MdsRow, MdsCol } from '@mds/layout-grid';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import { mapActions, mapGetters } from 'vuex';
import {
  _getFullSavedFormulaCode, _getValidatedFormulaUiCode,
  _getFullFormulaCodeWithoutAST, _isFreeformFormula,
} from '../../../scripts/utils/Manager';
import FormulaGenerator from '../../../scripts/FormulaGenerator';
import WorkflowVars from '../../../scripts/wfvars.es6';
import { generatePreviewResultsData, generateSummaryDataForPreviewResults } from '../../../scripts/PreviewResultsUtility';

const defaultOption = {
  text: '*** Choose one ***',
  value: 'default',
};

export default {
  name: 'WorkflowFormulaPrevresAction',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsForm,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsComboBox,
    MdsDatePicker,
    MdsRadioButton,
    MdsFieldSet,
    MdsAlert,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    workflowData: { type: Object, default: null },
    workflow: { type: Object, default: null },
    parameterSetData: { type: Object, default: null },
    initialData: { type: Object, default: null },
  },
  data() {
    return {
      showPreviewLoader: false,
      previewDateTypeDataset: [{ text: 'Latest Available', value: 'Latest Available' }, { text: 'Fixed', value: 'Fixed' }],
      currentInputToEdit: {
        name: '',
        body: {},
      },
      previewVars: [],
      inputNames: [],
      selectedPreviewVars: [],
      selectedInput: [],
      selectedDateType: 'latest_available',
      selectedDate: '',
      parameterSetModels: null,
      currentNodeFormula: '',
      formulaPreviewData: '',
      formulaGenerator: null,
      showError: false,
      errorMessage: '',
      manager: {},
      currentBubble: {},
      code: '',
      formulaPreviewDataOccurences: 0,
      previewDataResponse: null,
    };
  },
  computed: {
    ...mapGetters('workflowModule', ['getCurrentWorkflowManager', 'getFormulaDataNodeSet', 'getUnsavedFormula']),
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.clearPreviewDropDown();
  },
  methods: {
    ...mapActions('workflowModule', ['runFormula']),
    hideError() {
      this.showError = false;
    },
    init() {
      this.manager = this.getCurrentWorkflowManager;
      this.currentBubble = this.manager._priv.bubbles[this.node.id];
      this.formulaGenerator = new FormulaGenerator(this.workflow.ui.datasets, this.workflow.ui.single_vars);
      this.parameterSetModels = this.parameterSetData.parameterModels;
      this.selectedDate = new Date(this.formatDate(new Date().toISOString()));
      this.dataNodeSet = this.getFormulaDataNodeSet(this.node.id);
      if (['qa', 'formula'].includes(this.node.title)) {
        const { formulas } = this.manager._priv;
        const uuid = this.currentBubble.data('formula_id');
        if (uuid !== null) {
          if (!Object.hasOwn(formulas, uuid)) {
            throw new Error(`IllegalStateException: unregistered formula ID (${ uuid })`);
          }
          this.defaultCode = formulas[uuid].content;
        }
        let validatedCode = '';
        if (_isFreeformFormula(this.defaultCode)) {
          if (this.getUnsavedFormula.length > 0) {
            validatedCode = this.getUnsavedFormula;
          } else {
            validatedCode = this.defaultCode;
          }
        } else {
          validatedCode = _getValidatedFormulaUiCode(this.currentBubble, this.dataNodeSet);
        }

        this.code = _getFullFormulaCodeWithoutAST(this.currentBubble, validatedCode, true);
        if (this.code && this.code.length > 0) {
          this.currentNodeFormula = this.code;
        } else {
          this.currentNodeFormula = this.formulas.filter(f => f.uuid === this.node.data.formula_id)[0].formula || '';
        }

        this.previewVars = this.getInputVarsList();
        this.inputNames = this.getInputNames();
        if (this.initialData.previewVars && this.initialData.previewVars.length > 0) {
          this.$nextTick(() => {
            this.selectedPreviewVars.push(this.initialData.previewVars[0]);
            this.selectedInput.push(this.inputNames[0].value);
          });
        } else {
          this.$nextTick(() => {
            if (this.inputNames.length > 0) {
              this.selectedInput.push(this.inputNames[0].value);
            }
            if (this.previewVars.length > 0) {
              this.selectedPreviewVars.push(this.previewVars[0].value);
            }
          });
        }
      } else if (['save', 'publish'].includes(this.node.title)) {
        this.code = _getFullSavedFormulaCode(this.currentBubble, true);
        this.currentNodeFormula = this.formulas
          .filter(f => f.uuid === this.manager._priv.bubbles[this.node.data.connectedFormulaNodeId]._data.formula_id)[0]
          .formula;
        this.code = !this.code && this.currentNodeFormula;
      }
    },
    formatDate(dateString) {
      const date = dateString.split('T')[0].split('-');
      return `${date[1]}/${+date[2]}/${date[0]}`;
    },
    getInputVarsList() {
      const varsDict = WorkflowVars.getTopLevelVariables(this.currentNodeFormula);
      const varNames = Object
        .keys(varsDict)
        .sort((v1, v2) => (v1 - v2 ? 1 : -1))
        .map(v => ({
          value: v,
          text: `${v} - ${varsDict[v].code()}`,
        }));
      return varNames;
    },
    getInputNames() {
      return this.parameterSetData.parameterSetModels.map(psm => ({
        text: psm.name,
        value: psm.name,
      }));
    },
    generatePropMap(parameterSetModelArray = []) {
      const propMap = {};
      for (let i = 0; i < parameterSetModelArray.length; i++) {
        const prop = parameterSetModelArray[i];
        propMap[prop['propKey']] = prop['propValue'];
      }
      return propMap;
    },
    generatePreviewFormula(vars) {
      const suspendSaveFormulaRegex = /save_(curve|series)|(submit_pending_data)/gm;
      this.code = this.code.replace(suspendSaveFormulaRegex, '// $&');
      return [
        this.code,
        '',
        '// JsFormulaPreview: post-script',
        (
          (vars.length > 1)
            ? `as.csv(union(${vars.join(', ')}));`
            : `${vars[0]}.toString();`
        ),
      ].join('\n');
    },
    isDate(date) {
      return (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date));
    },
    getClassesForPreviewData(data) {
      return [
        'incons',
        // !data.includes(',') && isFinite(data) && 'green',
        // (data.includes(',') || (data.includes('-') || data.includes('/'))) && 'blue',
        // !data.includes(',') && data === 'NaN' && 'red',
        [
          'Max', 'Min',
          'Avg', 'AvgPos', 'AvgNeg',
          'PctPos', 'PctNeg',
          'Variance', 'StdDev', 'ZStat',
        ].includes(data) && 'blue',
      ]
        .filter(c => c)
        .join(' ');
    },
    prettifyDate(dateString) {
      if (!dateString) return dateString;
      if (!dateString.includes('T')) return dateString;
      const [rawDate, rawTime] = dateString.split('T');
      const date = rawDate.split('-');
      const time = rawTime.split('-')[0] || rawTime.split('+')[0];
      return `${date[1]}/${date[2]}/${date[0]}, ${time}`;
    },
    refreshFormula() {
      this.showError = false;
      if (this.selectedInput.length === 0 || this.selectedPreviewVars.length === 0) {
        this.showError = true;
        this.errorMessage = 'Please select all input fields required to preview data.';
        return;
      }

      this.formulaPreviewDataOccurences = 0;
      this.formulaPreviewData = '';
      this.showPreviewLoader = true;
      const input = this.selectedInput;
      const previewVars = this.selectedPreviewVars;
      const date = this.selectedDate;
      const parameterSetModel = this.parameterSetData.parameterSetModels
        .filter(psm => psm.name === input[0])[0].parameterModels || [];
      const propMap = this.generatePropMap(parameterSetModel);

      function formatDate(dateObj) {
        const d = new Date(dateObj);
        let month = `${ d.getMonth() + 1}`;
        let day = `${ d.getDate()}`;
        const year = d.getFullYear();

        if (month.length < 2) { month = `0${ month}`; }
        if (day.length < 2) { day = `0${ day}`; }

        return [year, month, day].join('-');
      }

      propMap['formula.script_name'] = 'preview.js';
      propMap['formula.is_saving_disabled'] = true;
      propMap['workflow.time_zone'] = this.workflow.timeZone;
      propMap['formula.run_date'] = date ? formatDate(date) : formatDate(new Date());

      if (this.selectedDateType === 'latest_available') {
        propMap['formula.force_latest_curve'] = true;
      }

      const formulaPayload = {
        type: 'JS',
        formula: this.generatePreviewFormula(previewVars),
        propMap,
      };

      this.runFormula(formulaPayload).then((response) => {
        let formulaPreviewRawData = null;
        if (typeof response.data === 'string') {
          formulaPreviewRawData = response.data.split('\n')
            .map(line => line.split(','))
            .filter(line => line[0])
            .map(dataRow => [this.prettifyDate(dataRow[0]), ...dataRow.slice(1)]);
        } else {
          formulaPreviewRawData = `${response.data}`
            .split('\n')
            .map(line => line.split(','))
            .filter(line => line[0])
            .map(dataRow => [this.prettifyDate(dataRow[0]), ...dataRow.slice(1)]);
        }
        this.formulaPreviewData = formulaPreviewRawData;

        try {
          if (!previewVars.find(v => v.startsWith('$'))) {
            const previewResultsSummaryData = generateSummaryDataForPreviewResults(
              generatePreviewResultsData(response.data).valuesColumn,
            );
            this.formulaPreviewDataOccurences = this.formulaPreviewData.length;
            this.formulaPreviewData.push(...previewResultsSummaryData);
          }
          this.showError = false;
        // eslint-disable-next-line no-empty
        } catch (error) {
          this.showError = true;
          this.errorMessage = (error.response && error.response.data) || error.message;
        }
      }).catch((error) => {
        this.showError = true;
        this.errorMessage = (error.response && error.response.data) || error.message;
      }).finally(() => {
        this.showPreviewLoader = false;
      });
    },
    clearPreviewDropDown() {
      this.$emit('clearPreviewDropDown');
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
.incons {
  font-family: 'Inconsolata', monospace !important;
}
.bold {
  font-weight: bold !important;
}
.red {
  background-color: #FFCFDF !important;
}
.blue {
  background-color: #CFF0FF !important;
}
.green {
  background-color: #CFFFE4 !important;
}
.purple {
  background-color: #E9CFFF !important;
}
#fullscreen .mds-select___Mcd-ui {
  margin-top: 2% !important;
}
.btn-row {
  padding-top: 1%;
}
.wf-edit-action {
  height: 97%;
}
.wf-edit-action form {
  height: 100%;
}
.prev-res-table {
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 1rem;
}
.formula-prev-result .mds-th__inner___Mcd-ui {
  justify-content: center !important;
}
</style>
